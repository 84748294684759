<template>
  <SectionWithMaxWidth data-testid="vonage-home-page-products-section">
    <Tabs class="hidden md:block pb-12" type="pill">
      <template #tabs>
        <Tab v-for="(tab, index) in blok.tabs" :key="tab._uid" :title="tab.title" :icon="tab.icon" :index="index" />
      </template>
      <template #panes>
        <TabPanel v-for="(tab, index) in blok.tabs" :key="tab._uid" :tab="tab" :index="index">
          <VonageHomePageProductTab :tab="tab" />
        </TabPanel>
      </template>
      <template #links>
        <NuxtLink href="/documentation" class="hidden xl:block">
          <VButton
            appearance="ghost"
            label="View all products"
            connotation="cta"
            icon="arrow-right-line"
            icon-trailing
            data-testid="documentation-link-button"
          />
        </NuxtLink>
      </template>
    </Tabs>

    <div
      v-for="(tab, index) in blok.tabs"
      :key="tab._uid"
      :icon="tab.icon"
      :index="index"
      class="md:hidden shadow-[0px_4px_4px_0px_rgba(0,0,0,0.05),_0px_8px_8px_0px_rgba(0,0,0,0.05),_0px_2px_16px_0px_rgba(0,0,0,0.10)] rounded-[22px] mb-4 px-8 sm:px-10 md:px-12 pt-0 pb-6"
    >
      <div
        class="cursor-pointer collapsible-label flex flex-row items-center justify-center gap-[0.5rem] flex-wrap"
        @click="toggleCollapse(tab._uid)"
      >
        <v-icon :name="tab.icon" :size="-5" class="mt-6" />
        <h4 class="flex-1 pt-0 mb-0">{{ tab.title }}</h4>
        <v-icon
          name="chevron-down-solid"
          :size="-6"
          class="collapsible-icon flex md:hidden mt-6"
          :class="{ expanded: isExpanded(tab._uid) }"
        />
      </div>
      <VonageHomePageProductTab v-if="isExpanded(tab._uid)" :tab="tab" class="collapsible-content" />
    </div>
    <div class="flex xl:hidden mt-6 md:mt-[-25px] mb-3 md:mb-11 lg:justify-end justify-start">
      <NuxtLink href="/documentation">
        <VButton
          appearance="ghost"
          label="View all products"
          connotation="cta"
          icon="arrow-right-line"
          icon-trailing
          data-testid="documentation-link-button"
        />
      </NuxtLink>
    </div>
  </SectionWithMaxWidth>
</template>

<script setup lang="ts">
import type { PropType } from 'vue';
import { VButton, VIcon } from '@vonage/vivid-vue';
import { Tab, TabPanel, Tabs } from '@/components/widgets/tabs';

import type { VonageHomePageProductsSection } from '@/types/storyblok';
import VonageHomePageProductTab from '@/components/home/vonage/products/VonageHomePageProductTab.vue';
import SectionWithMaxWidth from '@/components/utils/SectionWithMaxWidth.vue';
import { ref } from 'vue';

const props = defineProps({
  blok: { type: Object as PropType<VonageHomePageProductsSection>, required: true },
});

const expandedTab = ref<string>(props.blok.tabs[0]._uid);

const toggleCollapse = (uid: string) => {
  if (expandedTab.value !== null && expandedTab.value === uid) {
    expandedTab.value = '';
  } else {
    expandedTab.value = uid;
  }
};

const isExpanded = (uid: string) => {
  return expandedTab.value === uid;
};
</script>
<style scoped lang="scss">
.collapsible-icon {
  transition: transform 0.2s ease;
}

.collapsible-content {
  overflow: hidden;
  transition: max-height 0.2s ease;
}

.collapsible-icon.expanded {
  transform: rotate(180deg);
}

.collapsible-content.expanded {
  max-height: fit-content;
}
</style>
